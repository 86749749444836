.main-image-container{
	margin: 290px 0;
	padding: 0 100px;
    position: relative;
	display:flex;
	flex-direction: row;
    max-width: 1440px;
    width: calc(100% - 200px);
    height: 350px;
    max-height: 400px;
}

.main-image-container>div{
	flex-basis: 50%;
}

.main-image{
	background:url(../../images/MImage.png);
	background-size: cover;
	background-position: center;
}

.main-text{
	font-family: Open Sans;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 35px;
	letter-spacing: 0em;
	text-align: left;
	display: flex;
	align-items: center;
}

.main-text>span{
	height: min-content;
}

@media screen and (max-width:950px) {
	.main-image-container{
		margin: 150px 0;
		padding: 0 50px;
		width: calc(100% - 100px);
		height: 387px;
		max-height: 400px;
		flex-direction: column;
		max-height: none;
		height: auto;
	}
	.main-image{
		height: 400px;
	}
	.main-image-container>div{
		flex-basis: unset;
	}
	.main-text>span{
		text-align: left;
	}
	.main-text{
		font-size: 20px;
	}
}

@media screen and (max-width:650px) {
	.main-image-container{
		padding: 0 20px;
		width: calc(100% - 40px);
	}
	.main-image{
		height: 300px;
	}
	.main-text{
		font-size: 18px;
	}
	.main-text>span{
		text-align: center;
	}
	.map-container{
		display: none;
	}
}