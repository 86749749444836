.virtues-container {
  margin-top: 150px;
  position: relative;
  max-width: 1440px;
  padding: 0 100px 100px 100px;
  height: 750px;
  width: calc(100% - 200px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.virtues-column {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  flex-basis: 27%;
  flex-grow: 1;
}

.virtues-block {
  width: 100%;
  flex-basis: 30%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.virtues-icon {
  width: 52px;
  height: 52px;
  background-size: cover;
  background-position: center;
}

.stra-icon {
  background-image: url(../../images/strawberry.png);
}

.sugar-icon{
    background-image: url(../../images/sugar-free.png);
}

.leaf-icon{
    background-image: url(../../images/leaf.png);
}

.jam-icon{
    background-image: url(../../images/jam.png);
}

.calendar-icon{
    background-image: url(../../images/calendar.png);
}

.sunrise-icon{
    background-image: url(../../images/sunrise.png);
}

.virtues-text {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
}

.main-barries-image{
    flex-basis: 46%;
    background-image: url(../../images/MBI.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position:center;
}

@media screen and (max-width:980px) {
  .virtues-text {
    font-size: 14px;
  }

  .virtues-icon {
    width: 42px;
    height: 42px;
  }
  .main-barries-image{
    flex-basis: 33%;
  }
  .virtues-block {
    flex-basis: 33%;
  }
  .virtues-container {
    margin-top: 75px;
    padding: 0 50px 50px 50px;
    height: 550px;
    width: calc(100% - 100px);
  }
}

@media screen and (max-width:650px) {
  .virtues-text {
    font-size: 12px;
  }

  .virtues-icon {
    width: 32px;
    height: 32px;
  }
  .main-barries-image{
    flex-basis: 10%;
    background: none;
  }
  .virtues-block {
    flex-basis: 40%;
    margin-bottom: 40px;
  }
  .virtues-container {
    margin-top: 50px;
    padding: 0 20px 20px 20px;
    height: auto;
    width: calc(100% - 40px);
  }
}

@media screen and (max-width:500px) {
  .main-barries-image{
    display: none;
  }
  .virtues-block {
    margin-bottom: 30px;
  }
  .virtues-container {
    margin-top: 30px;
    flex-direction: column;
  }
}
