.footer{
    width: 100%;
    height: 150px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-items: flex-end;
    font-family: open sans;
    font-size: 14px;
    color: #8b949e;
}

.footer-content{
    width: min-content;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 100%;
    align-items: flex-end;
    margin-bottom: 20px;
}

.footer-logo{
    height: 25px;
    object-fit: cover;
    object-position: center;
    margin-right: 15px;
}
