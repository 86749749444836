.why-container {
  max-width: 1440px;
  padding: 0 100px;
  width: calc(100% - 200px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.why-title {
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.why-icons {
  margin-top: 80px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.why-block {
  padding: 0 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  max-width: auto;
  margin-right: 0;
}

.why-block-icon {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.apple-icon {
  background-image: url(../../images/apple.png);
}

.nature-icon {
  background-image: url(../../images/nature.png);
}

.pamphlet-icon {
  background-image: url(../../images/pamphlet.png);
}

.why-block-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: 40px;
  padding-right: 20px;
}

.why-block-primary,
.why-block-secondary {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.why-block-text:hover {
  cursor: default;
}

.why-block-secondary {
  font-weight: 400;
}

@media screen and (max-width: 980px) {
  .why-container {
    padding: 0 50px;
    width: calc(100% - 100px);
  }

  .why-icons {
    margin-top: 60px;
  }

  .why-title {
    font-size: 18px;
  }

  .why-icons {
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .why-block {
    flex-grow: 1;
    min-width: 290px;
    max-width: none;
    flex-basis: 33%;
    margin-bottom: 40px;
  }

  .why-block-primary,
  .why-block-secondary {
    font-size: 14px;
  }

  .why-block-icon {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 650px) {
  .why-title {
    font-size: 16px;
  }
  .why-container {
    padding: 0 20px;
    width: calc(100% - 40px);
  }

  .why-icons {
    margin-top: 40px;
  }

  .why-block {
    min-width: unset;
    margin-bottom: 30px;
  }

  .why-block-primary,
  .why-block-secondary {
    font-size: 12px;
  }
  .why-block-icon {
    width: 40px;
    height: 40px;
  }

  .why-block-text {
    margin-left: 20px;
  }
}

@media screen and (max-width: 500px) {
  .why-block-icon {
    width: 30px;
    height: 30px;
  }

  .why-title {
    font-size: 14px;
  }

  .why-block-text {
    margin-left: 15px;
  }
  .why-icons {
    margin-top: 30px;
  }
}
