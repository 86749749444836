@font-face {
	font-family: 'Open Sans'; 
  font-style: normal;
  font-weight: normal;
	src: url(./fonts/OpenSans/OpenSans-Regular.ttf); 
}
@font-face {
	font-family: 'Open Sans'; 
  font-style: normal;
  font-weight: bold;
	src: url(./fonts/OpenSans/OpenSans-Bold.ttf); 
}
@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    url(./fonts/MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}

:root{
  --main-bg-color:hsl(193, 95%, 95%);
  --header-text-color:hsl(193, 95%, 0%);
}

body {
  margin: 0;
  font-family: 'Open Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.material-icon{
  font-family: Material Icons;
}
