header {
  position: relative;
  padding: 0 100px;
  top: 0;
  left: 0;
  width: calc(100% - 200px);
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.header-content {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
}

.company-name {
  display: flex;
  align-items: center;
}

.company-name > span {
  color: var(--header-text-color);
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 30px;
  transition: all 0.4s ease-out;
}

.header-image {
  position: absolute;
  width: 230px;
  height: 230px;
  top: -130px;
  background: url(../../images/HImage.png);
  background-size: cover;
  transition: all 0.4s ease-out;
}

.contact-number {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-out;
}

.numbers {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
}

.phone-number {
  color: #000000;
  margin-left: 5px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 20px;
  /* identical to box height */
  letter-spacing: 0.035em;
  text-decoration: none;
}

.phone-number.additional {
  color: #8f8f8f;
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-top: 5px;
  /* identical to box height */
  letter-spacing: 0.035em;
  text-decoration: none;
}

@media screen and (max-width: 980px) {
  header {
    padding: 0 50px;
    width: calc(100% - 100px);
  }
}

@media screen and (max-width: 650px) {
  header {
    height: 70px;
    padding: 0 20px;
    width: calc(100% - 40px);
  }
  .header-image {
    width: 115px;
    height: 115px;
    top: -65px;
  }

  .phone-number {
    font-size: 18px;
  }
  .company-name > span {
    font-size: 18px;
  }
  .material-icon {
    font-size: 1rem !important;
  }
}

@media screen and (max-width: 500px) {
  .header-content {
    padding-top: 30px;
    align-content: center;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
  }
  .company-name {
    justify-content: center;
  }
  .header-image {
    width: 56px;
    height: 56px;
    top: -28px;
  }
}
