.about-us-container {
  margin-top: 100px;
  max-width: 1440px;
  width: calc(100% - 200px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  padding: 0 100px;
  align-items: stretch;
  justify-content: stretch;
}

.image-column {
  flex-basis: 22%;
  flex-grow: 0;
  margin-right: 80px;
  background-size: cover;
  background-position: center;
  background-image: url(../../images/about.png);
}

.about-us-column {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-basis: 70%;
  flex-grow: 1;
}

.about-us-title {
  white-space: nowrap;
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.about-text {
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.035em;
  text-align: left;
}

.card-panel {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-bottom: 135px;
  
}

.card {
  flex-basis: calc(33% - 15px);
  max-width: calc(33% - 15px);
  flex-grow: 1;
  margin-right: 15px;
  margin-bottom: 15px;
}

.card-image {
  width: 100%;
  height: 350px;
  background-position: center;
  background-size: cover;
}

.example-image-1 {
  background-image: url(../../images/ex1.jpg);
}

.example-image-2 {
  background-image: url(../../images/ex2.jpg);
}

.example-image-3 {
  background-image: url(../../images/ex3.jpg);
}

.example-image-4 {
  background-image: url(../../images/ex4.jpg);
}

.example-image-5 {
  background-image: url(../../images/ex5.jpg);
}

.example-image-6 {
  background-image: url(../../images/ex6.jpg);
}

.example-image-7 {
  background-image: url(../../images/ex7.jpg);
}

.card-text {
  margin-top: 16px;
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
}

@media screen and (max-width:980px) {
  .about-us-container {
    margin-top: 75px;
    width: calc(100% - 100px);
    padding: 0 50px;
  }

  .image-column {
    margin-right: 60px;
  }

  .about-us-title {
    font-size: 18px;
  }

  .about-text {
    padding-top: 18px;
  }

  .card-panel {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 100px;
  }

  .card-image {
    height: 250px;
  }

  .card-text {
    font-size: 16px;
  }
}

@media screen and (max-width:650px) {
  .about-us-container {
    margin-top: 50px;
    width: calc(100% - 40px);
    margin-top: 20px;
  }

  .image-column {
    margin-right: 30px;
  }

  .about-us-title {
    font-size: 16px;
  }

  .about-text {
    padding-top: 16px;
  }

  .card-panel {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 70px;
  }

  .card-image {
    height: 200px;
  }

  .card-text {
    font-size: 14px;
  }
}

@media screen and (max-width:500px) {
  .about-us-container {
    margin-top: 0;
  }

  .image-column {
    display: none;
  }

  .about-us-title {
    font-size: 14px;
  }

  .about-text {
    font-size: 14px;
  }

  .card-panel {
    margin-bottom: 40px;
  }
  
  .card{
    flex-basis: 100%;
    max-width: 100%;
  }

  .card-image {
    height: 200px;
  }

  .card-text {
    text-align: center;
    font-size: 12px;
  }
}
