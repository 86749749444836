.tab-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  border-color: transparent;
  border-style: solid;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
}

.maps-container {
  padding: 100px;
  max-width: 1440px;
  width: calc(100% - 200px);
}

.maps-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.maps-title {
  flex-shrink: 0;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
  letter-spacing: 0em;
  text-align: left;
}

.tabs-container {
  margin: 0 -15px -15px 0;
  flex-basis: 50%;
  margin-left: 100px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  overflow-y: auto;
  min-width: 400px;
}

button.tab {
  padding: 5px 30px;
  margin: 0 15px 15px 0;
  border-radius: 3px;
  font-family: Open Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
  background: #e5e5e5;
  color: #000;
  width: max-content;
  flex-shrink: 0;
}

button.tab:hover {
  background: hsl(353, 68%, 66%);
  color: #fff;
}

button.tab.selected {
  background: hsl(353, 68%, 51%);
  color: #fff;
}

.tab-header-image {
  position: absolute;
  width: 224px;
  height: 224px;
  right: -200px;
  top: -122px;
  background: url(../../images/tabs-image.png);
  background-size: cover;
  background-position: center;
}

.tabs-content {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: flex-start;
}

.content-header {
  font-family: Open Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.content-icons {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.block {
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin-right: 150px;
}

.clock-icon,
.map-point-icon {
  width: 50px;
  height: 50px;
  background-image: url(../../images/clock.png);
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
}

.map-point-icon {
  background-image: url(../../images/mapPoint.png);
}

.block-text {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-left: 40px;
}

.block-primary,
.block-secondary {
  font-family: Open Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  width: max-content;
}

.block-text:hover {
  cursor: default;
}

.block-secondary {
  font-weight: 300;
  font-size: 16px;
}

.map-container {
  margin-top: 100px;
  height: auto;
  width: 100%;
}

.map-container>svg{
  overflow: visible;
}

.marker-title {
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  fill: #de3636;
}

.marker-line {
  overflow: visible;
}

@media screen and (max-width: 980px) {
  .maps-container {
    padding: 50px;
    width: calc(100% - 100px);
  }

  .tab-header-image {
    right: -150px;
  }
  .maps-title {
    font-size: 18px;
  }

  .content-header {
    font-size: 18px;
  }

  button.tab {
    padding: 5px 15px;
    margin: 0 10px 10px 0;
    font-size: 18px;
    line-height: 30px;
  }

  .content-icons {
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .block {
    max-width: 250px;
    margin-right: 0;
    margin-bottom: 20px;
  }

  .block-primary,
  .block-secondary {
    font-size: 16px;
  }
}

@media screen and (max-width: 650px) {
  .tab-header-image {
    width: 112px;
    height: 112px;
    right: -70px;
    top: -41px;
  }

  .tabs-container {
    flex-basis: 50%;
    margin-left: 50px;
  }

  .maps-container {
    padding: 20px;
    width: calc(100% - 40px);
  }

  .maps-title {
    font-size: 16px;
  }

  .content-header {
    font-size: 16px;
  }

  button.tab {
    padding: 5px 10px;
    font-size: 16px;
    line-height: 30px;
  }

  .block-primary,
  .block-secondary {
    font-size: 14px;
  }

  .block-text {
    margin-left: 20px;
  }

  .clock-icon,
  .map-point-icon {
    width: 40px;
    height: 40px;
  }
  .map-container{
    margin-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .maps-header {
    flex-wrap: wrap;
  }

  .tab-header-image {
    width: 56px;
    height: 56px;
    right: -50px;
    top: -11px;
  }

  .maps-title {
    font-size: 14px;
    margin-right: 20px;
  }

  .content-header {
    font-size: 14px;
  }

  .tabs-container {
    flex-basis: 50%;
    margin-left: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  button.tab {
    padding: 5px 8px;
    font-size: 14px;
    line-height: 30px;
    white-space: nowrap;
  }

  .tabs-content,
  .content-icons {
    margin-top: 20px;
  }

  .block-primary,
  .block-secondary {
    font-size: 12px;
  }

  .block-text {
    margin-left: 15px;
  }

  .clock-icon,
  .map-point-icon {
    width: 30px;
    height: 30px;
  }
  .map-container{
    margin-top: 15px;
  }
}
