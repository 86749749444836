.adminContainer {
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.fairsContainer {
    display: table;
}
.fairsContainer .fair{
    display: table-row;
}
.fairHeader {
    display: table-header-group;
}
.fairsContainer span, .fairsContainer time{
    display: table-cell;
    padding: 20px;
    text-align: center;
    vertical-align: middle;
    border: 1px solid gray;
}
.buttonContainer{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.buttonContainer div{
    border: 1px solid gray;
    border-radius: 18px;
    padding: 5px 10px;
    cursor: pointer;
}
.editModalCover{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    backdrop-filter: blur(5px);
}
.editModalContainer{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    pointer-events: none;
}
.editModal{
    background-color: white;
    border-radius: 18px;
    padding: 20px;
    height: fit-content;
    width: fit-content;
    border: 1px solid gray;
    pointer-events: all;
}
.editModal form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.editModal form input{
    border: 1px solid gray;
    border-radius: 18px;
    padding: 5px 10px;
    width: 100%;
    outline: none;
}
.editModal form input[type="submit"]{
    cursor: pointer;
}